<template>
  <div class="dashboard">
    <Navbar />
    <div class="row g-0">
      <div class="col-md-3 d-md-block d-none col-sm-4 shadow bg-light">
        <Sidebar />
      </div>
      <div class="col-md-9 p-3">
        <div class="d-md-none text-start">
        <button
          class="btn btn-primary"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample"
          aria-controls="offcanvasExample"
        >
          <font-awesome-icon icon="sliders-h" />
        </button>
        </div>

        <router-view></router-view>
      </div>
    </div>

    <!-- off canvas -->
    <div
      class="offcanvas offcanvas-start bg-light"
      tabindex="-1"
      id="offcanvasExample"
      aria-labelledby="offcanvasExampleLabel"
    >
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasExampleLabel">เมนูหลัก</h5>
        <button
          type="button"
          class="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div class="offcanvas-body">
        <Sidebar />
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Sidebar from "@/components/Sidebar.vue";
export default {
  name: "Dashboard",
  components: {
    Navbar,
    Sidebar,
  },
};
</script>

<style>
</style>
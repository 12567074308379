<template>
  <div class="side-bar">
    <div class="p-3 bg-light pt-4" style="height: 100vh">
      <nav class="nav flex-column">
        <router-link
          :class="{ 'nav-link': true}"
          to="/dashboard"
          >ข้อมูลพื้นฐาน</router-link
        >
        <router-link
          :class="{ 'nav-link': true}"
          to="/dashboard/documents"
          >คำร้อง</router-link
        >
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "Sidebar",
};
</script>

<style scoped>
.nav-link {
  color: #000;
  padding: 15px 5px;
  margin-bottom: 0.5rem;
}

.nav-link.active,
.nav-link:hover, .router-link-exact-active {
  border-radius: 10px;
  color: #fff;
  background: #8e2de2; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #4a00e0,
    #8e2de2
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #4a00e0,
    #8e2de2
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
</style>